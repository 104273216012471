var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('DefaultLayout',{staticClass:"order",attrs:{"breadcrumbs":[{ name: 'Заявки', value: 'orders' }],"isHideFiltersPanel":true,"heading":"Заявка на ДТП"}},[_c('ValidationObserver',{ref:"form",staticClass:"maintenance-order-create order-form",attrs:{"tag":"form"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('div',{staticClass:"order-form__container sp-xl"},[_c('RequestFormPartWrapper',{attrs:{"name":"Тип","number":"01"}},[_c('ValidationProvider',{staticClass:"full-width",attrs:{"tag":"div","rules":"required","name":"crashType"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('Select',{attrs:{"label":"Категория повреждения*","small":true,"required":true,"error":errors[0],"items":_vm.categories,"hide-details":"","itemValue":"name","rules":[_vm.rules.select1]},on:{"changeSelect":_vm.onItemSelected},model:{value:(_vm.form.crashType),callback:function ($$v) {_vm.$set(_vm.form, "crashType", $$v)},expression:"form.crashType"}},[_c('Paragraph',{staticClass:"display-contents",attrs:{"tag":"span","size":"label","theme":"color-inherit"}},[_vm._v("Категория повреждения"),_c('b',[_vm._v("*")])])],1)]}}],null,true)})],1),_c('RequestFormPartWrapper',{attrs:{"name":"Подтип","number":"02"}},[_c('ValidationProvider',{staticClass:"full-width",attrs:{"tag":"div","rules":"required","name":"crashSubType"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('Select',{attrs:{"label":"Подкатегория повреждения*","small":true,"required":true,"error":errors[0],"items":_vm.subcategories,"disabled":!_vm.form.crashType,"hide-details":"","itemValue":"name","rules":[_vm.rules.select2]},model:{value:(_vm.form.crashSubType),callback:function ($$v) {_vm.$set(_vm.form, "crashSubType", $$v)},expression:"form.crashSubType"}},[_c('Paragraph',{staticClass:"display-contents",attrs:{"tag":"span","size":"label","theme":"color-inherit"}},[_vm._v("Подкатегория повреждения"),_c('b',[_vm._v("*")])])],1)]}}],null,true)})],1),_c('RequestFormPartWrapper',{attrs:{"name":"Виновность","number":"03"}},[_c('ValidationProvider',{staticClass:"full-width",attrs:{"tag":"div","rules":"required","name":"guilt"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('Select',{attrs:{"label":"Виновность","small":true,"required":true,"error":errors[0],"items":_vm.culpabilities,"itemValue":"name","rules":[_vm.rules.select3]},model:{value:(_vm.form.guilt),callback:function ($$v) {_vm.$set(_vm.form, "guilt", $$v)},expression:"form.guilt"}},[_c('Paragraph',{staticClass:"display-contents",attrs:{"tag":"span","size":"label","theme":"color-inherit"}},[_vm._v("Виновность"),_c('b',[_vm._v("*")])])],1)]}}],null,true)})],1),_c('RequestFormPartWrapper',{attrs:{"name":"Повреждения","number":"04"}},[_c('ValidationProvider',{staticClass:"full-width",attrs:{"tag":"div","rules":"required","name":"damage"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('VTextarea',{staticClass:"no-padding-top confirmation",attrs:{"required":true,"error-messages":errors[0],"maxlength":"900","counter":"","rows":"1","no-resize":false,"auto-grow":"","rules":[_vm.rules.count]},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('Paragraph',{staticClass:"display-contents",attrs:{"tag":"span","size":"label","theme":"color-inherit"}},[_vm._v("Повреждения"),_c('b',[_vm._v("*")])])]},proxy:true}],null,true),model:{value:(_vm.form.damage),callback:function ($$v) {_vm.$set(_vm.form, "damage", $$v)},expression:"form.damage"}})]}}],null,true)})],1),_c('RequestFormPartWrapper',{attrs:{"name":"Дата ДТП","number":"05"}},[_c('ValidationProvider',{attrs:{"tag":"div","rules":"required","name":"crashDate"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('DatePicker',{staticClass:"full-width",attrs:{"range":false,"label":"Дата ДТП","required":true,"error":errors[0],"small":true,"is-next-max-date":true,"rules":[_vm.rules.date]},model:{value:(_vm.form.crashDate),callback:function ($$v) {_vm.$set(_vm.form, "crashDate", $$v)},expression:"form.crashDate"}},[_c('Paragraph',{staticClass:"display-contents",attrs:{"tag":"span","size":"label","theme":"color-inherit"}},[_vm._v("Дата ДТП"),_c('b',[_vm._v("*")])])],1)]}}],null,true)})],1),_c('RequestFormPartWrapper',{attrs:{"name":"Автомобиль","number":"06"}},[_c('CarFormPart',{attrs:{"id":_vm.form.carId,"isTO":true},on:{"update:id":function($event){return _vm.$set(_vm.form, "carId", $event)}}})],1),_c('RequestFormPartWrapper',{attrs:{"name":"Менеджер","number":"07"}},[_c('CharacterCard',{attrs:{"formPartId":"03","data":_vm.form.author,"formPartName":"Менеджер"},on:{"update:data":function($event){return _vm.$set(_vm.form, "author", $event)}}})],1),_c('RequestFormPartWrapper',{attrs:{"name":"Документы<b class='color-red'>*</b>","number":"08"}},[_c('ValidationProvider',{ref:"fileProvider",attrs:{"tag":"div","rules":"required","name":"fileIds"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('Dropzone',{staticClass:"full-width",attrs:{"info":'Прикрепить справки из ГИБДД МВД',"is-invalid":!_vm.form.fileIds.length && errors[0],"error":errors[0]},on:{"update":function($event){_vm.form.fileIds = $event, _vm.$refs['fileProvider'].validate($event)}}})]}}],null,true)})],1),_c('RequestFormPartWrapper',{attrs:{"name":"Фото<b class='color-red'>*</b>","number":"09"}},[_c('ValidationProvider',{ref:"photoProvider",attrs:{"tag":"div","rules":"required","name":"photoIds"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('Dropzone',{staticClass:"full-width",attrs:{"info":'Прикрепить фото повреждений',"is-invalid":!_vm.form.photoIds.length && errors[0],"error":errors[0]},on:{"update":function($event){_vm.form.photoIds = $event, _vm.$refs['photoProvider'].validate($event)}}})]}}],null,true)})],1),(_vm.isShowComment)?_c('RequestFormPartWrapper',{staticClass:"comments",attrs:{"name":"Комментарий","isCloseble":true},on:{"close":_vm.handleCloseComment}},[_c('TextInput',{staticClass:"full-width",attrs:{"label":"Комментарий","hide-details":"","small":true,"maxlength":"500","counter":"","rules":[_vm.rules.count]},model:{value:(_vm.form.comment),callback:function ($$v) {_vm.$set(_vm.form, "comment", $$v)},expression:"form.comment"}})],1):_vm._e()],1),_c('div',{staticClass:"order-form__container"},[_c('ControlsRequestFormPart',{on:{"submit":_vm.handleSubmit}},[(!_vm.isShowComment)?_c('Link',{attrs:{"tag":"div","mod":"dark-blue","icon":"mdi-message-processing"},on:{"click":function($event){_vm.isShowComment = true}}},[_vm._v("Добавить комментарий")]):_vm._e()],1)],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }